import { useRouter } from 'next/router';
import GeneralIcon from 'src/components/core/atoms/GeneralIcon';
import {
  INVENTORY_PAGE,
  ORGANIZATION_PAGE,
  PageContextEnum,
  ROUTES
} from 'src/config';
import useAuthPersistStore, {
  AuthStoreType
} from './store/useAuthPersistStore';
import useSearchDialogStore, {
  SearchDialogStoreType
} from './store/useSearchDialogStore';

//--------------------------------------------------------------------------------------

export default function useAllPages(mockPathname = '') {
  const { addContextualBreadcrumbs } = useSearchDialogStore(
    (store: SearchDialogStoreType) => ({
      addContextualBreadcrumbs: store.addContextualBreadcrumbs
    })
  );
  const { authState } = useAuthPersistStore(
    (store: AuthStoreType) => ({
      authState: store.authState
    })
  );
  const router = useRouter();
  const pathname = mockPathname || router.pathname;

  const allPages: {
    [key in PageContextEnum]: {
      label: string;
      parent: string;
      action: () => void;
      icon: JSX.Element;
    };
  } = {
    home: {
      label: 'Home',
      parent: null,
      action: () => router.push(ROUTES.ROOT),
      icon: <GeneralIcon mode="grey" type="homepage" />
    },
    dashboard: {
      label: 'Dashboard',
      parent: null,
      action: () => router.push(ROUTES.DASHBOARD),
      icon: <GeneralIcon mode="grey" type="homepage" />
    },
    marketplace: {
      label: 'Marketplace',
      parent: authState?.isAuthenticated ? 'dashboard' : 'home',
      action: () => router.push(ROUTES.MARKETPLACE.LIST),
      icon: <GeneralIcon mode="grey" type="marketplace" />
    },
    // 'my-offsets': {
    //   label: 'My Offsets',
    //   parent: 'dashboard',
    //   action: () => router.push(ROUTES.MY_OFFSETS),
    //   icon: <GeneralIcon mode="grey" type="my-offsets" />
    // },
    organization: {
      label: 'Organization',
      parent: 'null',
      action: () => {
        router.push(ROUTES.INVENTORY.MEASURE);
        addContextualBreadcrumbs({
          crumbs: [
            {
              key: 'locations',
              label: 'Locations'
            },
            {
              key: ORGANIZATION_PAGE.LOCATIONS,
              label: 'Locations'
            }
          ],
          after: 'dashboard'
        });
      },
      icon: <GeneralIcon mode="grey" type="inventory" />
    },
    locations: {
      label: 'Locations',
      parent: 'organization',
      action: () => {
        if (pathname !== ROUTES.ORGANIZATION.LOCATIONS) {
          router.push(ROUTES.ORGANIZATION.LOCATIONS);
        }
      },
      icon: <GeneralIcon mode="grey" type="inventory" />
    },
    vehicles: {
      label: 'Vehicles',
      parent: 'organization',
      action: () => {
        if (pathname !== ROUTES.ORGANIZATION.VEHICLES) {
          router.push(ROUTES.ORGANIZATION.VEHICLES);
        }
      },
      icon: <GeneralIcon mode="grey" type="uploads" />
    },
    equipment: {
      label: 'Equipment',
      parent: 'organization',
      action: () => {
        if (pathname !== ROUTES.ORGANIZATION.EQUIPMENT) {
          router.push(ROUTES.ORGANIZATION.EQUIPMENT);
        }
      },
      icon: <GeneralIcon mode="grey" type={'my-offsets'} />
    },
    inventory: {
      label: 'Carbon Inventory',
      parent: 'dashboard',
      action: () => {
        router.push(ROUTES.INVENTORY.MEASURE);
        addContextualBreadcrumbs({
          crumbs: [
            {
              key: 'inventory',
              label: 'Carbon Inventory'
            },
            {
              key: INVENTORY_PAGE.MEASURE,
              label: 'Measure'
            }
          ],
          after: 'dashboard'
        });
      },
      icon: <GeneralIcon mode="grey" type="inventory" />
    },
    measure: {
      label: 'Measure',
      parent: 'dashboard',
      action: () => {
        if (pathname !== ROUTES.INVENTORY.MEASURE) {
          router.push(ROUTES.INVENTORY.MEASURE);
        }
      },
      icon: <GeneralIcon mode="grey" type="inventory" />
    },
    reduce: {
      label: 'Reduce',
      parent: 'inventory',
      action: () => {
        if (pathname !== ROUTES.INVENTORY.REDUCE) {
          router.push(ROUTES.INVENTORY.REDUCE);
        }
      },
      icon: <GeneralIcon mode="grey" type="uploads" />
    },
    share: {
      label: 'Share',
      parent: 'inventory',
      action: () => {
        if (pathname !== ROUTES.INVENTORY.SHARE) {
          router.push(ROUTES.INVENTORY.SHARE);
        }
      },
      icon: <GeneralIcon mode="grey" type={'my-offsets'} />
    },
    playground: {
      label: 'GraphQL Playground',
      parent: authState?.isAuthenticated ? 'dashboard' : 'home',
      action: () => router.push(ROUTES.PLAYGROUND),
      icon: <GeneralIcon mode="grey" type="playground" />
    },
    signin: {
      label: 'Sign In',
      parent: 'home',
      action: () => router.push(ROUTES.AUTH.SIGNIN),
      icon: <GeneralIcon mode="grey" type="account" />
    },
    signup: {
      label: 'Signup',
      parent: 'home',
      action: () => router.push(ROUTES.AUTH.SIGNUP),
      icon: <GeneralIcon mode="grey" type="account" />
    },
    pricing: {
      label: 'Pricing',
      parent: 'home',
      action: () => router.push(ROUTES.RESOURCES.PRICING),
      icon: <GeneralIcon mode="grey" type="my-wallet" />
    },
    settings: {
      label: 'Settings',
      parent: 'home',
      action: () => router.push(ROUTES.SETTINGS.OVERVIEW),
      icon: <GeneralIcon mode="grey" type="account" />
    }
  };
  return allPages;
}
